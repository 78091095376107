
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import {
  getLanguageList,
  editLanguage,
  addLanguage,
  isEditAuthByCode,
  languageOneClickTranslation
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  rowsData: Array<any>
  editFlag: boolean
  editForm: any
  searchDataAny: any //搜索条件
  isTranslation: boolean //是否翻译
  languages: Array<any>
  oneClickTranslationText: any
  oneClickTranslationIsNullText:any
  isOneClickTranslation:boolean//是否停止翻译
}

export default defineComponent({
  name: 'sysLanguageTable',
  components: {},
  setup() {
      const languageRef = ref()

    
    const validateKey = (rule:any, value:any, callback:any) => {
//         var str = "ab.c1.23.def";
             if (value === '' || value == null) {
                callback(new Error('请输入 KEY'))
             } else if (/^[\w]+$/.test(value)) { 
              callback()
             } else if (! (/^([\w]+[.]{1}[\w]+)*$/.test(value) )) { 
                callback(new Error('输入 KEY 格式不正确'))
             }else {
                callback()
            }
        }

    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件
      oneClickTranslationText: '一键翻译',
      oneClickTranslationIsNullText: '一键翻译为空语言',
      isOneClickTranslation:false,
      isTranslation:false,
      rowsData: [],
      languages: [
        {value:'tw',label:'繁体'},
        {value:'en',label:'英语'},
        {value:'az',label:'阿塞拜疆语'},
        {value:'cs',label:'捷克语'},
        {value:'es',label:'西班牙语'},
        {value:'fr',label:'法语'},
        {value:'de',label:'德语'},
        {value:'he',label:'希伯来语'},
        {value:'hu',label:'匈牙利语'},
        {value:'it',label:'意大利语'},
        {value:'fa',label:'波斯语'},
        {value:'pt',label:'葡萄牙语'},
        {value:'ru',label:'俄语'},
        {value:'tr',label:'土耳其语'},
        {value:'ar',label:'阿拉伯'},
        {value:'th',label:'泰语'},
        {value:'pl',label:'波兰语'}
      ],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      rules: {
          key: [{
              required: true,/**, message: '请输入 KEY' */validator:validateKey , trigger: 'blur' }],
        cn: [{ required: true, message: '请输入 中文', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })


    //编辑
    const editorFun = (e: any) => {
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true      
      deviceData.editFlag = true
      deviceData.isTranslation = false;
      deviceData.oneClickTranslationText = "一键翻译";
      deviceData.oneClickTranslationIsNullText = '一键翻译为空语言';
      }
      
      const addFun = () => {
          deviceData.editForm = { isEdit: false };
      deviceData.isTranslation = false;
      deviceData.editFlag = true
      deviceData.oneClickTranslationText = "一键翻译";
      deviceData.oneClickTranslationIsNullText = '一键翻译为空语言';
    }
    
    const editSave = async () => {
      languageRef.value.validate(async (valid: any) => {
         if (valid) {
          deviceData.isTranslation = false;
         deviceData.loading = true
          let array: any = {}
          Object.assign(array, deviceData.editForm)
          let fun = addLanguage
          if (deviceData.editForm.isEdit) {
            fun = editLanguage
          }
          delete array.createTime
          delete array.updateTime
          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.data)
              deviceData.loading = false;
            }
          })
        } else {
          return false
        }
      })
      }
    

      const oneClickTranslation = (countryCode: any) => {
         languageRef.value.validate(async (valid: any) => {
                if (valid) {
                    if (deviceData.isTranslation) {
                        if (countryCode != null && countryCode.length > 0 && countryCode != 'isNull') {
                            return;
                        }
                        ElMessage.error("翻译运行停止中......");
                        deviceData.oneClickTranslationText = "一键翻译";
                        deviceData.oneClickTranslationIsNullText = '一键翻译为空语言';
                        deviceData.isOneClickTranslation = true;
                        return;
                    }
                    deviceData.oneClickTranslationText = "停止翻译";
                    deviceData.oneClickTranslationIsNullText = '停止翻译';
                    deviceData.isTranslation = true;
                    deviceData.isOneClickTranslation = false;
                   let cn = deviceData.editForm.cn;
                    if (countryCode != null && countryCode.length > 0 && countryCode != 'isNull') {
                        let obj;
                        for (obj of deviceData.languages) {
                            if (obj.value == countryCode) {
                                break;
                            }
                        }
                        
                        ElMessage.info("翻译：" + obj.label)
                        deviceData.editForm[countryCode] = '';
                        await languageOneClickTranslation({text:deviceData.editForm.cn,countryCode:countryCode}).then((res: any) => {
                            let { code, data } = res
                            deviceData.isTranslation = false;
                            deviceData.oneClickTranslationText = "一键翻译";
                        deviceData.oneClickTranslationIsNullText = '一键翻译为空语言';
                            // if (correctData(cn)) {
                            //     ElMessage.error("翻译运行停止，数据被修改......")
                            //     return;
                            // }
                            if (code == 0) {
                                deviceData.editForm[countryCode] = data;
                            } else {
                                ElMessage.error(data)
                            }
                        })
                    } else {
                        // let isStop = false;
                        for (let obj of deviceData.languages) {
                            if (deviceData.editFlag == false || deviceData.isOneClickTranslation) {
                                deviceData.isTranslation = false;
                                return;
                            }
                            // if (correctData(cn)) {
                            //     if (!isStop) {
                            //         isStop = true;
                            //         ElMessage.error("翻译运行停止，数据被修改......")
                            //     }
                            //     return;
                            // }

                            ElMessage.closeAll();
                        if(countryCode == 'isNull'){
                          //这里只翻译为空的,不为空就不用翻译了
                          if(deviceData.editForm[obj.value]){
                            ElMessage.info("当前不用翻译：" + obj.label+"  , "+countryCode)
                             continue;
                          }
                        }
                        ElMessage.info("翻译：" + obj.label)
                        deviceData.editForm[obj.value] = '';
                        await languageOneClickTranslation({text:cn,countryCode:obj.value}).then((res: any) => {
                            let { code, data } = res
                            if (deviceData.editFlag == false || deviceData.isOneClickTranslation) {
                                deviceData.isTranslation = false;
                                return;
                            }                            
                            // if (correctData(cn)) {
                            //     if (!isStop) {
                            //         isStop = true;
                            //         ElMessage.error("翻译运行停止，数据被修改......")
                            //     }
                            //     return;
                            // }
                            if (code == 0) {
                                deviceData.editForm[obj.value] = data;
                            } else {
                                ElMessage.error(data)
                            }
                        })
                        }
                     deviceData.oneClickTranslationText = "一键翻译";
                     deviceData.oneClickTranslationIsNullText = '一键翻译为空语言';
                    ElMessage.info("翻译运行结束......")
                    deviceData.isTranslation = false;
                }
  
        } else {
          return false
        }
      })
      }

      //校验一下数据是否被修改，如果被修改就清空要翻译的数据
    //   const correctData = (cn: any) => {
    //       if (deviceData.editForm.cn != cn) {
    //           for (let obj of deviceData.languages) {
    //               delete deviceData.editForm[obj.value];
    //           }
    //           return true;
    //       }
    //       return false;
    //   }
    
    const search = () => {
      deviceData.currentPage = 1
      getList()
    }


    const getList = async () => {
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.loading = true
      await getLanguageList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.rowsData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.rowsData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }


    return {
      ...data,
      addFun,
      languageRef,
      editSave,
        search,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
    isEditAuthByCode,
    oneClickTranslation
    }
  },
})
